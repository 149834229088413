import React from 'react'
import UserContext from '../utils/UserContext'
import PuffLoader from 'react-spinners/PuffLoader'

import { Link } from 'react-router-dom'
import { getMyLists, getCommunityLists } from '../utils/Network'
import { animateScroll as scroll } from 'react-scroll'
import { useState, useContext, useEffect } from 'react'


const tabs = [
    {
        name: 'My Lists',
    },
    // {
    //     name: 'Saved',
    // },
    {
        name: 'From the community',
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const ListList = () => {

    const [lists, setLists] = useState([])
    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const [pageNumber, setPageNumber] = useState(0)
    const [nextPageToken, setNextPageToken] = useState(null)

    const { user } = useContext(UserContext)

    const handleTabClick = (index) => {

        setActiveTabIndex(index)
        fetchData(index)
        scroll.scrollToTop({ duration: 400, smooth: true, })
    }

    useEffect(() => {

        if (user) {

            fetchData(0)
        }

    }, [user])

    async function fetchData(index) {

        if (index == 0) {
            try {

                const response = await getMyLists(user.userId)
                setLists(response)
            }
            catch (error) {

                console.error(error)
            }
        }
        else {
            try {

                const response = await getCommunityLists(user.userId)
                setLists(response)
            }
            catch (error) {

                console.error(error)
            }
        }
    }


    return (

        <div className="bg-white pt-2 lg:pt-12 pb-32">
            <div className="mx-auto max-w-7xl lg:px-8">

                <div className="px-6 lg:px-0 hidden lg:flex items-center justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="my-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Lists
                        </h2>
                    </div>
                    <div className="hidden lg:flex mt-5 lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
                            <button type="button" className="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-70 transform transition-opacity duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" >
                                <span aria-hidden="true" className="mr-1.5 text-md material-symbols-outlined">add</span>
                                <span className='h-full'>Create a list</span>
                            </button>
                        </span>
                    </div>
                </div >

                {/* Tabs */}
                <div>
                    <div className="border-b border-gray-200">
                        <nav aria-label="Tabs" className="px-6 lg:px-0 flex space-x-8">

                            {tabs.map((tab, index) => (
                                <a key={index} href="#" aria-current={tab.current ? 'page' : undefined} onClick={() => handleTabClick(index)}
                                    className={classNames(
                                        index == activeTabIndex
                                            ? 'border-gray-900 text-gray-900 font-medium'
                                            : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 font-medium',
                                        'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm transform transition-all duration-300 ease-in-out',
                                    )} >
                                    {tab.name}
                                </a>

                            ))}

                        </nav>
                    </div>
                </div>

                {user ? (

                    <ul role="list" className="mt-4 lg:mt-8 divide-y divide-gray-100 transform transition-all duration-300 ease-in-out">
                        {lists.map((list, index) => (

                            <li key={index} className="flex group items-center justify-between gap-x-6 py-5 rounded-xl transform transition-all duration-300 ease-in-out hover:bg-gray-50 px-6 lg:-mx-6 lg:px-6">

                                <div className="min-w-0">
                                    <div className="flex flex-col items-start">
                                        <p className="text-lg font-semibold leading-6 text-gray-900">{list.name.S}</p>
                                        <div className='flex gap-x-3 mt-3'>
                                            {list.tags.SS.map((tag, index) => (
                                                <p key={index} className='text-gray-600 mt-0.5 whitespace-nowrap rounded-full bg-gray-100 group-hover:bg-gray-200 transform transition-all duration-300 ease-in-out px-3 py-1.5 text-xs font-medium' >
                                                    {tag}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-3 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        <p className="truncate">Created on {new Date(list.createdAt.N * 1000).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
                                        {/* <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                            <circle r={1} cx={1} cy={1} />
                                        </svg> */}
                                    </div>
                                </div>

                                <div className="flex flex-none items-center gap-x-4">

                                    <Link to={`/lists/${list.listId.S}`} state={{ list }}>
                                        <button className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:block hover:opacity-70 transform transition-opacity duration-300 ease-in-out" >
                                            View list<span className="sr-only">, {list.name.S}</span>
                                        </button>
                                    </Link>

                                </div>
                            </li>
                        ))}
                    </ul>

                ) : (

                    <PuffLoader className='mx-auto my-48' />
                )}
            </div >
        </div >

    )
};

export default ListList;
