import React from 'react'
import UserContext from '../utils/UserContext'
import wordmarkIdeashots from '../images/ideashots_wordmark.svg'

import { useContext } from 'react'
import { Link } from 'react-router-dom'

const userTabs = [
    { name: 'My Lists', href: '/lists' }
]

const Header = () => {

    const { user } = useContext(UserContext)

    return (

        <header className="bg-white">

            <nav className="flex mx-auto max-w-7xl items-start justify-between py-8 lg:px-8">

                <a href="/" className="flex flex-col py-2 px-6 lg:px-0">
                    <img alt="" src={wordmarkIdeashots} className="w-32 hover:opacity-60 transform transition-all duration-300 ease-in-out" />
                    <span className='mt-2 text-sm font-medium text-gray-900'>Find inspiration from the world's ideas and innovations</span>
                </a>

                <div className='flex lg:hidden px-6'>
                    {user ? (

                        <img src={user.image.S} className='w-8 rounded-full group-focus:outline group-focus-visible:outline group-focus:outline-4 group-focus:outline-gray-300 group-focus-visible:outline-4 group-focus-visible:outline-gray-300' />
                    ) :
                        (
                            <div></div>
                        )
                    }
                </div>

                <div className="hidden lg:flex justify-end">
                    {user ? (

                        <div className="hidden lg:flex lg:items-center lg:gap-x-6">
                            {userTabs.map((item) => (
                                <Link to={item.href} key={item.href} className="text-sm font-semibold leading-6 text-gray-900 hover:opacity-70 transform transition-opacity duration-300 ease-in-out">
                                    {item.name}
                                </Link>
                            ))}
                            {/* <button className='flex hover:opacity-60 transform transition-opacity duration-300 ease-in-out'>
                                <span className="h-6 w-6 material-symbols-outlined">
                                    notifications
                                </span>
                            </button> */}

                            <Link to={'/profile'} className="flex block rounded-full text-gray-500 hover:text-gray-900 focus:outline-none focus-visible:outline-none group">
                                <img src={user.image.S} className='w-10 rounded-full group-focus:outline group-focus-visible:outline group-focus:outline-4 group-focus:outline-gray-300 group-focus-visible:outline-4 group-focus-visible:outline-gray-300' />
                            </Link>

                        </div>


                    ) :
                        (
                            <Link to={'/login'}>
                                <button className="bg-gray-800 text-white font-bold py-2 px-4 rounded hover:opacity-80 transform transition-all duration-300 ease-in-out">
                                    Log In
                                </button>
                            </Link>

                        )

                    }

                </div>

                <nav className='flex fixed z-10 justify-evenly bottom-0 h-20 w-full border-t-2 py-2 border-solid border-gray-200 bg-white lg:hidden'>

                    <Link to={'/'} className='flex flex-col items-center justify-center gap-y-1'>
                        <span className='text-2xl font-medium text-gray-500 material-symbols-outlined'>home</span>
                        <span className='text-sm font-medium text-gray-500'>Home</span>
                    </Link>

                    <Link to={'/lists'} className='flex flex-col items-center justify-center gap-y-1'>
                        <span className='text-2xl font-medium text-gray-500 material-symbols-outlined'>list</span>
                        <span className='text-sm font-medium text-gray-500'>Lists</span>
                    </Link>

                    <Link to={'/profile'} className='flex flex-col items-center justify-center gap-y-1'>
                        <span className='text-2xl font-medium text-gray-500 material-symbols-outlined'>account_circle</span>
                        <span className='text-sm font-medium text-gray-500'>Profile</span>
                    </Link>

                </nav>

            </nav>



        </header>

    );
};

export default Header;
