import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBGsuRketnPejYGQdgpXvoq7qHbHjoFQxU",
    authDomain: "ideashots.ai",
    projectId: "ideashots-2d0aa",
    storageBucket: "ideashots-2d0aa.appspot.com",
    messagingSenderId: "931363531032",
    appId: "1:931363531032:web:62cee0ac55f83afb10d66d",
    measurementId: "G-2LGBP6C6VN"
};

firebase.initializeApp(firebaseConfig);
const firebaseProvider = new firebase.auth.GoogleAuthProvider();

export async function loginWithGoogle() {
    
    const result = await firebase.auth().signInWithPopup(firebaseProvider);

    return result.user;
}

export async function logout() {
    
    firebase.auth().signOut().then(function () {
               
    }, 
    function (error) {
        
        console.error('Sign Out Error', error);
        
    });

}

export default firebase;

