import './index.css'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './utils/UserContext'
import { TabProvider } from './utils/TabContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<UserProvider>
		<TabProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</TabProvider>
	</UserProvider>
)