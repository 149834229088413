import React from 'react'
import PuffLoader from 'react-spinners/PuffLoader'
import TabContext from '../utils/TabContext'

import { Link } from 'react-router-dom'
import { getPosts } from '../utils/Network'
import { animateScroll as scroll } from 'react-scroll'
import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Popover, PopoverButton, PopoverPanel, PopoverBackdrop, CloseButton } from '@headlessui/react'

const tabs = {
    "categories": [
        {
            "title": {
                "S": "Editor's Pick"
            }
        },
        {
            "title": {
                "S": "Sustainability"
            },
            "subcategories": [
                {
                    "title": {
                        "S": "Renewable Energy"
                    }
                },
                {
                    "title": {
                        "S": "Water"
                    }
                },
                {
                    "title": {
                        "S": "Air Pollution"
                    }
                },
                {
                    "title": {
                        "S": "Waste"
                    }
                },
                {
                    "title": {
                        "S": "Food"
                    }
                },
                {
                    "title": {
                        "S": "Apparel"
                    }
                },
                {
                    "title": {
                        "S": "Housing "
                    }
                }
            ],
            "trending": [
                {
                    "title": {
                        "S": "Converting Wind to Fuel"
                    },
                    "excerpt": {
                        "S": "Converting excess wind energy into hydrogen or fuels, PtX enables storage and supports a future hydrogen economy."
                    }
                },
                {
                    "title": {
                        "S": "Protecting Wind Turbine Blades"
                    },
                    "excerpt": {
                        "S": "Advanced coatings extend blade life, improving wind turbine durability and efficiency, especially in harsh climates."
                    }
                },
            ]
        },
        {
            "title": {
                "S": "Transportation"
            },
            "subcategories": [

            ],
        },
        {
            "title": {
                "S": "Clothes and Fashion"
            },
            "subcategories": [

            ],
        }
    ]
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PostList = () => {

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [section, page] = pathname.split('/').slice(1)

    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { activeTabIndex, setActiveTabIndex } = useContext(TabContext)

    const [pageNumber, setPageNumber] = useState(0)
    const [nextPageToken, setNextPageToken] = useState(null)
    const [popoverOpen, setPopoverOpen] = useState(false)

    useEffect(() => {

        const firstLoad = async () => {

            setIsLoading(true)
            try {

                const l1Categories = ['sustainability', 'transportation', 'clothes-and-fashion']

                if (section && l1Categories.includes(section.toLowerCase())) {

                    setActiveTabIndex(l1Categories.indexOf(section.toLowerCase()) + 1)
                    const response = await getPosts(section.toLowerCase().replace(/-/g, " "), 0, null)

                    setPosts(response.posts)
                    setPageNumber(0)
                    setNextPageToken(response.nextPage)

                }
                else {

                    setActiveTabIndex(0)
                    const response = await getPosts(tabs.categories[0].title.S, 0, null)

                    setPosts(response.posts)
                    setPageNumber(0)
                    setNextPageToken(response.nextPage)
                }

            }
            catch (error) {

                console.error(error)
            }

            setIsLoading(false)
        }

        firstLoad()

    }, [])

    const fetchFirstPage = async () => {

        setIsLoading(true)
        try {

            const response = await getPosts(tabs.categories[activeTabIndex].title.S, 0, null)

            setPosts(response.posts)
            setPageNumber(0)
            setNextPageToken(response.nextPage)

        }
        catch (error) {

            console.error(error);
        }

        setIsLoading(false)
        scroll.scrollToTop({ duration: 400, smooth: true, });
    }

    const fetchNextPage = async () => {

        setIsLoading(true)
        try {

            const response = await getPosts(tabs.categories[activeTabIndex].title.S, pageNumber + 1, nextPageToken)

            setPosts(response.posts)
            setPageNumber(pageNumber + 1)
            setNextPageToken(response.nextPage)
        }
        catch (error) {

            console.error(error);
        }

        setIsLoading(false)
        scroll.scrollToTop({ duration: 400, smooth: true, })
    }

    const handleTabClick = async (index) => {

        const tab = tabs.categories[index]
        setActiveTabIndex(index)
        navigate('/' + tab.title.S.toLowerCase().replace(/\s/g, "-").replace(/'/g, ''), { replace: true })

        setIsLoading(true)
        try {

            const response = await getPosts(tab.title.S, 0, null)
            setPosts(response.posts)
            setPageNumber(0)
            setNextPageToken(response.nextPage)

        }
        catch (error) {

            setPosts(null)
            setPageNumber(0)
            setNextPageToken(null)
            console.error(error)
        }

        setIsLoading(false)

    }

    const handleSubcategoryClick = async (index, subIndex) => {

        setIsLoading(true)
        try {

            const response = await getPosts(tabs.categories[index].subcategories[subIndex].title.S, 0, null)
            setPosts(response.posts)
            setPageNumber(0)
            setNextPageToken(response.nextPage)

        }
        catch (error) {

            setPosts(null)
            setPageNumber(0)
            setNextPageToken(null)
            console.error(error)
        }

        setPopoverOpen(false)
        setIsLoading(false)
    }

    return (

        <div className="bg-white pt-2 pb-32 lg:pt-12">
            <div className="mx-auto max-w-7xl lg:px-8">

                {/* Tabs */}
                <div>
                    <div className="sm:block">
                        <div className="border-b border-gray-200">
                            <nav aria-label="Tabs" className="px-6 lg:px-0 flex space-x-8 overflow-x-scroll lg:overflow-x-hidden">

                                {tabs.categories.map((category, index) => (

                                    <div className='flex' key={index}>
                                        {index == 0 ? (
                                            <a onClick={() => handleTabClick(index)}
                                                className={classNames(
                                                    index == activeTabIndex
                                                        ? 'border-gray-900 text-gray-900 font-medium'
                                                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 font-medium',
                                                    'flex items-center gap-x-3 whitespace-nowrap border-b-2 px-1 py-4 text-sm transform transition-all duration-300 ease-in-out cursor-pointer',
                                                )} >
                                                {category.title.S}
                                            </a>
                                        ) :
                                            (
                                                <Popover className="relative" open={popoverOpen} >

                                                    <PopoverButton className={classNames(
                                                        index == activeTabIndex
                                                            ? 'border-gray-900 text-gray-900 font-medium'
                                                            : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 font-medium',
                                                        'flex items-center gap-x-3 whitespace-nowrap border-b-2 px-1 py-5 text-sm transform transition-all duration-300 ease-in-out cursor-pointer focus:outline-none focus-visible:outline-none focus-within:outline-none',)}>
                                                        {category.title.S}
                                                        <span className='text-lg leading-none material-symbols-outlined'>keyboard_arrow_down</span>
                                                    </PopoverButton>

                                                    <PopoverBackdrop className="fixed inset-0 bg-black/15" />

                                                    <PopoverPanel transition anchor="bottom" className="flex w-full !mx-auto !max-w-7xl !max-h-fit !left-0 right-0 lg:px-8 !overflow-visible transition data-[open]:opacity-100 data-[open]:translate-y-0 data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
                                                        <div className="bg-white w-full mx-auto px-6 py-10 lg:p-12 shadow-lg ring-1 ring-gray-900/5 ">

                                                            <div className="w-full grid grid-cols-1 gap-x-8">
                                                                <div className='grow'>
                                                                    <CloseButton onClick={() => handleTabClick(index)} className="flex text-md font-bold leading-6 text-gray-900 cursor-pointer hover:opacity-60 transition transition-opacity duration-300 ease-in-out">
                                                                        View all in {category.title.S}<span className='material-symbols-outlined leading-none'>chevron_right</span>
                                                                    </CloseButton>
                                                                    <div className="mt-4 flow-root">
                                                                        <div className="flex flex-wrap gap-x-8 gap-y-2">
                                                                            {category.subcategories.map((subcategory, subIndex) => (
                                                                                <CloseButton key={subIndex} onClick={() => handleSubcategoryClick(index, subIndex)} className="flex gap-x-4 py-1 text-sm leading-6 text-gray-900 cursor-pointer hover:opacity-60 transition transition-opacity duration-300 ease-in-out" href="/insights">
                                                                                    {subcategory.title.S}
                                                                                </CloseButton>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="inline-flex flex-col">
                                                                    <h3 className="text-md font-bold leading-6 text-gray-900">Editor's Picks</h3>
                                                                    <div className='flex gap-x-6 mt-4'>
                                                                        {category.trending.map((post, index) => (
                                                                            <article key={index} className="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch" >
                                                                                <div className="relative flex-none">
                                                                                    <img alt="" src="https://ideashots-posts.s3.amazonaws.com/c481590d-febe-46c6-ba03-c1eb5299f727.jpg" className="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto" />
                                                                                    <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                                                                                </div>
                                                                                <div>
                                                                                    <div className="flex items-center gap-x-4">
                                                                                        <a className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100" >
                                                                                            Renewable Energy
                                                                                        </a>
                                                                                    </div>
                                                                                    <h4 className="mt-2 text-sm font-semibold leading-6 text-gray-900">
                                                                                        <a>
                                                                                            <span className="absolute inset-0" />
                                                                                            {post.title.S}
                                                                                        </a>
                                                                                    </h4>
                                                                                    <p className="mt-2 text-sm leading-6 text-gray-600 line-clamp-2">{post.excerpt.S}</p>
                                                                                </div>
                                                                            </article>
                                                                        ))}
                                                                    </div>
                                                                </div> */}
                                                            </div>

                                                        </div>
                                                    </PopoverPanel>

                                                </Popover>
                                            )}
                                    </div>

                                ))}

                            </nav >
                        </div>
                    </div>
                </div>

                <div>
                    {isLoading ? (

                        <PuffLoader className='mx-auto my-48' />

                    ) :
                        (

                            <div>

                                {/* Posts */}
                                {posts ? (
                                    <div className='mx-auto my-6 lg:mt-12 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-16 px-6 lg:px-0 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
                                        {posts.map((post) => (

                                            <Link key={post.postId.S} to={`/post/${post.slug.S}`}>
                                                <article className="flex flex-col items-start justify-between">

                                                    <div className="w-full">
                                                        <img
                                                            alt=""
                                                            src={post.featuredImage.S}
                                                            className="aspect-[16/9] w-full bg-gray-100 rounded-xl lg:rounded-2xl object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                                        />
                                                    </div>

                                                    <div className="max-w-xl">

                                                        <div className="group relative text-left">
                                                            <h3 className="mt-5 text-2xl font-semibold tracking-tight leading-tight text-gray-900 group-hover:text-gray-600 text-left">
                                                                {post.title.S}
                                                            </h3>
                                                            <p className="mt-4 line-clamp-3 text-sm leading-relaxed text-left text-gray-600">{post.excerpt.S}</p>
                                                        </div>

                                                        <div className='flex items-center'>
                                                            <div className="my-4 flex flex-wrap items-center gap-2 text-xs">
                                                                {post.categories.SS.map((category, index) => (
                                                                    <div key={index} className="rounded-md bg-gray-100 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100" >
                                                                        {category}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </article>
                                            </Link>

                                        ))}
                                    </div>
                                ) :
                                    (
                                        <div className='mx-auto my-48 flex flex-col justify-center items-center'>
                                            <span className='mx-auto my-4 text-6xl text-gray-500 material-symbols-outlined'>sentiment_neutral</span>

                                            <h3 className="mt-2 text-lg font-semibold text-gray-900">No posts here</h3>
                                            <p className="mt-1 text-md text-gray-500">Check out one of the other categories</p>
                                        </div>
                                    )
                                }

                                {/* Pagination */}
                                <nav className="flex mt-16 items-center justify-between border-t border-gray-200 px-4 sm:px-0">

                                    {pageNumber > 0 ? (

                                        <div className="flex justify-end">
                                            <button onClick={fetchFirstPage} className="inline-flex items-center border-t-2 border-transparent px-1 py-4 gap-x-3 text-sm font-medium text-gray-500 hover:border-gray-400 hover:opacity-70 transform transition-all duration-300 ease-in-out " >
                                                <span aria-hidden="true" className="material-symbols-outlined text-gray-900">chevron_left</span>
                                                <span className="text-gray-900 pl-r">Back to first page</span>
                                            </button>
                                        </div>

                                    ) :
                                        (

                                            <div></div>

                                        )}


                                    {nextPageToken ? (

                                        <div className="flex justify-end">
                                            <button onClick={fetchNextPage} className="inline-flex items-center border-t-2 border-transparent px-1 py-4 gap-x-3 text-sm font-medium text-gray-500 hover:border-gray-400 hover:opacity-70 transform transition-all duration-300 ease-in-out " >
                                                <span className="text-gray-900 pl-2">Next</span>
                                                <span aria-hidden="true" className="material-symbols-outlined text-gray-900">chevron_right</span>
                                            </button>
                                        </div>

                                    ) :
                                        (
                                            <div></div>
                                        )}
                                </nav>

                            </div>
                        )}

                </div>
            </div>
        </div>

    )
}

export default PostList
