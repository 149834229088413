import React from 'react'
import UserContext from '../utils/UserContext'
import PuffLoader from 'react-spinners/PuffLoader'

import { Link } from 'react-router-dom'
import { RWebShare } from 'react-web-share'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { getPostById } from '../utils/Network'
import { animateScroll as scroll } from 'react-scroll'
import { useState, useEffect, useContext } from 'react'

const Post = () => {

    const { postId } = useParams()
    const { user } = useContext(UserContext)

    const [post, setPost] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    scroll.scrollToTop({ duration: 400, smooth: true, });

    useEffect(() => {

        const firstLoad = async () => {

            setIsLoading(true)
            try {

                const response = await getPostById(postId)
                setPost(response)
            }
            catch (error) {

                console.error(error)
            }

            setIsLoading(false)
        }

        firstLoad()

    }, [])

    return (

        <div className="bg-white lg:pt-12 pb-32">

            {isLoading ? (

                <PuffLoader className='mx-auto my-48' />

            ) :
                (

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-24 mx-auto max-w-7xl lg:px-8 lg:pb-24">

                        <Helmet>
                            <title>{post.title.S}</title>
                            <meta data-rh="true" name='description' content={post.excerpt.S} />
                            <meta data-rh="true" property="og:title" content={post.title.S} />
                            <meta data-rh="true" property="og:description" content={post.excerpt.S} />
                            <meta data-rh="true" property="og:image" content={post.featuredImage.S} />
                        </Helmet>

                        <div>
                            <img
                                alt=""
                                src={post.featuredImage.S}
                                className="aspect-[16/9] bg-gray-100 rounded-none lg:rounded-xl object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                            />
                        </div>
                        <div className="px-6">

                            {/* <div className="flex items-center gap-x-2 text-xs">
                                {post.categories.SS.map((category, index) => (
                                    <a key={index} className="relative rounded-md bg-gray-100 px-3 py-1.5 font-medium text-sm text-gray-600 hover:bg-gray-100">
                                        {category}
                                    </a>
                                ))}
                            </div> */}
                            <h1 className="mt-5 mb-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{post.title.S}</h1>
                            {/* <p className="text-sm font-normal leading-7 text-gray-500">By {post.author.S}, on {new Date(post.createdAt.N * 1000).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</p> */}
                            <p className="mt-3 text-base text-gray-800 font-normal leading-relaxed">
                                {post.content.S}
                            </p>

                            <div className="my-5 flex flex-row flex-wrap gap-2">
                                <a href={post.source.S} target='_blank' className="relative flex items-center gap-x-2 rounded-md bg-gray-100 px-3 py-1.5 font-medium text-sm text-gray-600 hover:bg-gray-100" >
                                    Source
                                    <span className='text-xl leading-none material-symbols-outlined'>link</span>
                                </a>
                                <RWebShare
                                    data={{
                                        text: post.excerpt.S,
                                        url: window.location.href,
                                        title: post.title.S,
                                    }}>
                                    <button className='rounded-md bg-gray-100 px-3 py-1.5 font-medium text-sm text-gray-600 hover:bg-gray-100'>Share on Web</button>
                                </RWebShare>
                            </div>
                            <div className='my-12'>
                                <span className='mt-5 text-sm font-medium text-gray-700'>Companies</span>

                                <div className="mt-4 flex flex-row flex-wrap gap-2">
                                    {post.companies.SS.map((company, index) => (
                                    <a key={index} className="relative rounded-md bg-gray-100 px-3 py-1.5 font-medium text-sm text-gray-600 hover:bg-gray-100">
                                        {company}
                                    </a>
                                ))}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }


        </div>

    )
}

export default Post;

