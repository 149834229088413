import React from 'react'
import UserContext from '../utils/UserContext'
import PuffLoader from 'react-spinners/PuffLoader'

import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getPostsInList } from '../utils/Network'
import { animateScroll as scroll } from 'react-scroll'
import { useState, useContext, useEffect } from 'react'

const List = () => {

    const { user } = useContext(UserContext)
    const [posts, setPosts] = useState(null)
    const [isEmpty, setIsEmpty] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const location = useLocation()
    const list = location.state?.list

    scroll.scrollToTop({ duration: 400, smooth: true, })

    useEffect(() => {

        const firstLoad = async () => {

            setIsLoading(true)
            try {

                const response = await getPostsInList(list.listId.S)
                if (response.status == 200) {
                    setPosts(response.data.Responses.Posts)
                }
                else {
                    setIsEmpty(true)
                    setIsLoading(false)
                }
            }
            catch (error) {

                console.error(error)
            }

            setIsLoading(false)
        }

        firstLoad()

    }, [])

    return (

        <div className="bg-white pt-2 lg:pt-12 pb-32">
            <div className="mx-auto max-w-7xl lg:px-8">

                {posts == null ? (

                    <div>

                        {isEmpty ? (
                            <span>No posts in this list</span>
                        ) : (
                            <PuffLoader className='mx-auto my-48' />
                        )}

                    </div>

                ) : (

                    <div className="px-6 lg:px-0">
                        <div className="min-w-0 flex-1">
                            <h2 className="my-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                {list.name.S}
                            </h2>
                            {/* <span>{list}</span> */}
                        </div>

                        {/* Posts */}
                        <div className='mx-auto my-6 lg:mt-12 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-16 px-6 lg:px-0 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
                            {posts.map((post) => (

                                <Link key={post.createdAt.N} to={`/post/${post.postId.S}`} state={{ post }}>
                                    <article className="flex flex-col items-start justify-between">

                                        <div className="relative w-full">
                                            <img
                                                alt=""
                                                src={post.featuredImage.S}
                                                className="aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>

                                        <div className="max-w-xl">

                                            <div className="group relative text-left">
                                                <h3 className="mt-5 text-2xl font-semibold tracking-tight leading-tight text-gray-900 group-hover:text-gray-600 text-left">
                                                    {post.content.S}
                                                </h3>
                                                <p className="mt-4 line-clamp-3 text-sm leading-relaxed text-left text-gray-600">{post.excerpt.S}</p>
                                            </div>

                                            <div className='flex items-center'>

                                                <div className="my-4 flex items-center gap-x-4 text-xs">
                                                    <div className="relative rounded-md bg-gray-100 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100" >
                                                        {post.category.S}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </article>
                                </Link>

                            ))}

                        </div>
                    </div>
                )}

            </div>
        </div>

    );
}

export default List
