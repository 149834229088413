import React from 'react'

import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useUserContext } from '../utils/UserContext'

const Profile = () => {

    const { user, setUser } = useUserContext()
    const [userName, setUserName] = useState('')
    const [userBio, setUserBio] = useState('')

    useEffect(() => {

        if (user) {
            
            setUserName(user.name.S)
            setUserBio(user.bio.S)
        }

    }, [user]);


    const updateUser = async () => {



    }

    return (

        <div className="bg-white pt-2 lg:pt-12 pb-32">
            <div className="mx-auto max-w-2xl px-6 lg:px-8">

                {user ? (

                    <div>

                        <div className="border-b border-gray-900/10 pb-8">
                            <div className="min-w-0 flex-1">
                                <h2 className="my-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                    Profile
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    This information will be displayed publicly so be careful what you share.
                                </p>
                            </div>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div className="col-span-full">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Your Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="display-name"
                                            name="display-name"
                                            type="text"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        About
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows={3}
                                            value={userBio}
                                            onChange={(e) => setUserBio(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                                </div>


                            </div>
                        </div>

                        <div className="flex mt-8 items-center justify-end gap-x-6">
                            <button onClick={updateUser} className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white hover:opacity-70 transform transition-opacity duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" >
                                Save profile
                            </button>
                        </div>
                    </div>

                ) :
                    (

                        <div className="mt-48 text-center">
                            {/* <span class="text-6xl text-gray-400 material-symbols-outlined">account_circle</span> */}
                            <h3 className="mt-2 text-2xl font-semibold text-gray-900">Nothing to see here</h3>
                            <p className="mt-2 text-base text-gray-500">Get started by logging in</p>
                            <div className="mt-6">
                                <Link to={'/login'}>
                                    <button type="button" className="inline-flex items-center rounded-md bg-gray-900 px-4 py-3 gap-x-2 text-sm font-semibold text-white shadow-sm  hover:opacity-70 transform transition-opacity duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" >
                                        Log in to get started
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )}

            </div>
        </div >

    )
}

export default Profile
