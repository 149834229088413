import axios from 'axios'

const devEndpoint = 'https://c6g533jeo4.execute-api.ap-south-1.amazonaws.com/dev'

export async function doesUserExist(userId) {

    try {

        const response = await axios.get(devEndpoint + '/users/' + userId + '/exists')
        return response.data
    }
    catch (error) {

        console.error(error)
        return false
    }
}

export async function createUser(user) {

    try {

        const createdUser = {
            userId: user.uid,
            email: user.email,
            image: user.photoURL,
            name: user.displayName
        }

        const response = await axios.post(devEndpoint + '/users/create', createdUser)
        return response
    }
    catch (error) {

        return error
    }
}

export async function getUser(userId) {

    try {

        const response = await axios.get(devEndpoint + '/users/' + userId)
        return response;
    }
    catch (error) {

        return null;
    }

}

export async function getPosts(category, pageNumber, nextPageToken) {

    const params = {
        category: category,
        pageNumber: pageNumber,
        nextPageToken: nextPageToken
    }

    try {

        const response = await axios.get(devEndpoint + '/posts', { params })
        return response.data
    }
    catch (error) {

        console.error(error)
        throw error
    }

}

export async function updateUser(user) {

    try {

        // const response = await axios.post(devEndpoint + '/users/update', user)
        // return response
    }
    catch (error) {

        return error
    }
}

export async function getMyLists(userId) {

    try {

        const response = await axios.get(devEndpoint + '/users/' + userId.S + '/lists/')
        return response.data.Items;
    }
    catch (error) {

        return null;
    }
}

export async function getCommunityLists(userId) {

    try {

        const response = await axios.post(devEndpoint + '/lists/community', {userId: userId.S})
        return response.data.Items
    }
    catch (error) {

        console.log(error)
        return null;
    }
}

export async function getPostsInList(listId) {

    try {

        const response = await axios.get(devEndpoint + '/lists/' + listId + '/getPosts')
        //return response.data.Responses.Posts
        return response
    }
    catch (error) {

        console.log(error)
        return null;
    }
}

export async function getPostById(postId)
{
    try {

        const response = await axios.get(devEndpoint + '/posts/' + postId)
        return response.data;
    }
    catch (error) {

        return null;
    }
}