import React from 'react'
import Post from './components/Post'
import List from './components/List'
import Login from './components/Login'
import Header from './components/Header'
import Profile from './components/Profile'
import PostList from './components/PostList'
import ListList from './components/ListList'

import { Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

function App() {
	return (
		<HelmetProvider>
			<Header />
			<Routes>
				<Route path="/" element={<PostList />} />
				{/* <Route path="/sustainability" element={<PostList />} />
				<Route path="/transportation" element={<PostList />} /> */}
				<Route path="/:category" element={<PostList />} />
				<Route path="/:category/:page" element={<PostList />} />
				<Route path="/post/:postId" element={<Post />} />
				<Route path="/login" element={<Login />} />
				<Route path='/lists' element={<ListList />} />
				<Route path="/lists/:listId" element={<List />} />
				<Route path='/profile' element={<Profile />} />
			</Routes>
		</HelmetProvider>
	)
}

export default App
