import React from 'react'

import { logout } from './Firebase'
import { getUser, createUser } from './Network'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { createContext, useState, useContext, useEffect } from 'react'

const UserContext = createContext()
export const useUserContext = () => useContext(UserContext)

export const UserProvider = ({ children }) => {

	const [user, setUser] = useState(null)

	useEffect(() => {
		const auth = getAuth()

		onAuthStateChanged(auth, (authUser) => {

			if (authUser) {

				getUser(authUser.uid)
					.then(response => {

						switch (response.status) {
						    case 200:
						        {
						            setUser(response.data.Item)
						            break
						        }

						    case 204:
						        {
						            createUser(authUser)
										.then(response => {

											if (response.status = 200) {

												setUser(response.data.user)
											}
											else {
		
												console.log(response)
												logout()
											}
										})
						            
						            break;
						        }

						    default:
						        {
						            console.log(response)
						            logout()

						            break
						        }
						}
					}
					)
					.catch(error => console.error('Error fetching user:', error))
			}
			else {

				setUser(null);
			}
		})
	}, [])

	return (

		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	)
}

export default UserContext
