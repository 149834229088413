import { createContext, useState } from 'react'

const TabContext = createContext()

export const TabProvider = ({ children }) => {
	const [activeTabIndex, setActiveTabIndex] = useState()

	return (
		<TabContext.Provider value={{ activeTabIndex, setActiveTabIndex }}>
			{children}
		</TabContext.Provider>
	)
}

export default TabContext